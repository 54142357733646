<template>
  <div class="whiteBack">
    <div class="mb-3">
      <!-- <label>Upload Your ID</label> -->
      <div class="mt-3">
        <input type="file" ref="file" id="imageUpload" accept="image/*" @change="previewImage" hidden>
        <button @click="chooseFile()" class="btn btn__primary mb-2" v-if="!userProfile.id_card_path">Upload Image</button>
        <button @click="chooseFile()" class="btn btn__primary mb-2" v-if="userProfile.id_card_path">Upload New Image</button>
      </div>
      <progress :value="uploadValue" max="100" v-if="showBar"></progress>
    </div>

    <div v-if="userProfile && userProfile.id_card_path">
      <a :href="userProfile.id_card_path" v-if="userProfile.id_card_path" target="_blank" class="caption">
        <img v-if="userProfile.id_card_path" :src="userProfile.id_card_path" alt="" class="profileId">
      </a>
      
    </div>
</div>
	</div>
</template>

<style scoped>
  img.profileId {
    width:50%;
    height:auto;
  }
</style>

<script>
const fb = require('../../firebaseConfig.js')

export default {
  props: ['userProfile'],
  data: () => ({ 
    imageData: null,
    uploadValue: 0,
    showBar:false,
    picture: null,
    performingRequest: false,
  }),
  methods: {
    chooseFile() {
        document.getElementById("imageUpload").click()
    },
    previewImage(event) {
      this.uploadValue=0;
      this.picture=null
      this.imageData=event.target.files[0]
      this.onUpload()
    },
    onUpload() {
      this.showBar = true
      let userProfile = this.userProfile
      userProfile.id_card_path=null
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url) => {
          userProfile.id_card_path=url
          this.$store.dispatch('updateUser', userProfile)
        })
        this.showBar = false
      })
      this.imageData = null
      this.$refs.file.value=null
    },
    // deleteUploadedFile(u, index) {
    //   console.log(u)
    //   let userProfile = this.userProfile
    //   userProfile.certs.splice(index, 1)
    //   this.$store.dispatch('updateUser', userProfile)
    // },
    onFileTypeMismatch(file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },
    onFileSizeExceed(file) {
      alert('Please choose a file smaller than 2MB')
    },
  }
}
</script>