<template>
  <div class="dashboard__container--body" v-if="(userProfile  && userProfile.id)">
    <div class="dashboard__container--body--col shiftCard">
      <ProfileID2 :userProfile="userProfile" class="mb-5" />
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ProfileID2 from '@/components/Profile/ProfileID2.vue'

export default {
  name: 'accountId',
  computed: {
    ...mapState(['userProfile', 'currentUser']),
  },
   components: {
    Loader,
    ProfileID2,
  },
}
</script>